import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../modules/auth/services/auth.service'
@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(private _authService: AuthService, private _route: Router) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this._authService.getIsAuthenticated() != null) {
      if (this._authService.getIsAuthenticated().subscribe(res => {
        if (res['login'])
          return true;
        else {
          return false;
        }
      })) {
        return true;
      }
      else {
        this._route.navigate(['/auth'])
        return false;
      }
    }
    else {
      this._route.navigate(['/auth'])
      return false;
    }
  }
}

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const nav = this.router.getCurrentNavigation();
    if (nav.id === 1 || nav.previousNavigation === null) {
      this.router.navigate(['/']);
    } else {
      return true;
    }
  }
}
