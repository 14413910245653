import { Component, OnDestroy, OnInit } from '@angular/core';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { NetworkService } from './network.service';

@Component({
  selector: 'app-network-status',
  templateUrl: './network-status.component.html',
  styleUrls: ['./network-status.component.scss']
})
export class NetworkStatusComponent implements OnInit, OnDestroy {



  subscriptions: Subscription[] = [];

  connectionStatusMessage: string;
  connectionStatus: string;

  istokenAvailable: boolean;
  constructor(public ntService: NetworkService) { }

  ngOnInit(): void {
    this.ntService.onlineEvent = fromEvent(window, 'online');
    this.ntService.offlineEvent = fromEvent(window, 'offline');

    this.subscriptions.push(this.ntService.onlineEvent.subscribe(e => {
      this.connectionStatusMessage = 'Back to online';
      this.connectionStatus = this.isLoggedin() ? 'is_online' : '';
      this.removeStatus();
    }));

    this.subscriptions.push(this.ntService.offlineEvent.subscribe(e => {
      this.connectionStatusMessage = 'Connection lost! You are not connected to internet';
      this.connectionStatus = this.isLoggedin() ? 'is_offline' : '';
    }));

    // Socket status
    this.ntService.networkStatus$.subscribe({
      next: (data) => {
        this.istokenAvailable = this.isLoggedin();
        if (data?.status !== '') {
          this.connectionStatus = data?.status === 'online' ? 'is_online': 'is_offline';
          this.connectionStatusMessage = data?.message;
          if (data?.status === 'online') {
            this.removeStatus();
          }
        }
      }
    })
  }

  isLoggedin(): boolean {
    return !!localStorage.getItem('token');
  }

  removeStatus(): void {
    setTimeout(() => {
      this.connectionStatus = '';
    }, 5000);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

}
