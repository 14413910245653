export const colors = [ '#0080ff', '#3a3a3a']

export interface Theme {
    name: string,
    properties: object
}
export const themes : { [key: string] : Theme } = {
    default: {
        name: 'Light-theme',
        properties: {
            'primary-color': '#0080ff',
            'secondary-color': '#3c506a'
        }
    }
} 