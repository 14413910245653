import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export interface NetworkStatusData {
  status: string;
  message: string;
};

@Injectable({
  providedIn: 'root'
})
export class NetworkService {

  public onlineEvent: Observable<Event>;
  public offlineEvent: Observable<Event>;

  public networkStatus$ = new BehaviorSubject({
    status: '',
    message: ''
  });

  constructor() { }

  setNetworkStatus(data: NetworkStatusData): void {
    this.networkStatus$.next(data);
  }
}
