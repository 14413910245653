import { Injectable } from '@angular/core';
import { Theme, themes } from '../modules/agent/@theme/constants/_colors';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  private activeTheme: Theme;
  constructor() { }

  getAllThemes(){
    return themes;
  }

  setActiveTheme(theme: Theme){
    this.activeTheme = theme;
    Object.keys(theme.properties).forEach(property => {
      document.documentElement.style.setProperty(
        `--${property}`,
        theme.properties[property]
      )
    });
  }

  getActiveTheme(){
    return this.activeTheme;
  }
}
