import { Injectable } from '@angular/core';
import { io, Socket } from 'socket.io-client';
import { environment } from 'src/environments/environment';
import { NetworkService } from '../modules/shared/components/network-status/network.service';
import { WebSocketMessageData } from '../modules/shared/shared.interface';
@Injectable()
export class WebsocketService {

  socket: Socket;

  constructor(public ntService: NetworkService) {
  }

  public connect(): void {
    let userData = JSON.parse(localStorage.getItem('userData'));
    const token = localStorage.getItem('token');
    if (!this.socket?.connected && token) {
      this.socket = io(environment.URL, {
        path: "/agent-desktop-be-ccai/agent-dekstop",
        query: {
          token: localStorage.getItem('token'),
          agentId: userData?.agentEmail,
          profile: userData?.conversationProfileId
        }
      });
      this.socket.on('connect', () => {
        this.ntService.setNetworkStatus({status: 'online', message: 'You are Online.'})
      });
      this.socket.on('disconnect', () => {
        this.ntService.setNetworkStatus({status: 'offline', message: 'You are Offline'})
      });
    }
  }

  getSocket(): Socket {
    return this.socket;
  }

  getSocketState() {
    return this.socket.connected;
  }

  sendMessages(type: string, data: WebSocketMessageData): void {
    this.socket?.emit(type, { data });
  }

  reconnect(): void {
    this.socket.disconnect();
    this.connect();
  }
}
