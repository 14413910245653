import { Component, ElementRef, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'agent-dashboard';

  constructor(private _elementRef: ElementRef,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer) {
    this.matIconRegistry
      .addSvgIcon("q-action", this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/action.svg"))
      .addSvgIcon("q-outcome", this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/outcome.svg"))
      .addSvgIcon("q-situation", this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/situation.svg"))
      .addSvgIcon("q-article", this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/q-article.svg"))
      .addSvgIcon("q-assist", this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/q-assist.svg"))
      .addSvgIcon("q-faqs", this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/q-faqs.svg"))
      .addSvgIcon("q-smartreply", this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/q-smartreply.svg"))
  }

  ngOnInit(): void {
    this._elementRef.nativeElement.removeAttribute("ng-version");
  }
}
