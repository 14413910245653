import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { projectConfig } from 'src/app/modules/agent/@theme/models/projectConfig';
@Injectable({
  providedIn: 'root'
})
export class DataService {
  private projectConfig = new BehaviorSubject<projectConfig>(null);
  private conversation = new BehaviorSubject<string>(null);
  private participantId = new BehaviorSubject<string>(null);
  private transcripts = new BehaviorSubject<object>(null);
  private view = new BehaviorSubject<boolean>(false);
  private copyToChatbox = new Subject<string>();
  private galleryImages = new BehaviorSubject<[]>([]);
  private documents = new BehaviorSubject<[]>([]);
  private clientLogo = new BehaviorSubject<string>(null);
  private newConversation = new Subject<object>();
  private message = new Subject<object>();
  private broadcastChannel = new BroadcastChannel('test_channel');
  private socketState : number;
  private isQChatConversation: boolean = false;
  private regex = new RegExp("projects\/(?<projectId>(.*))\locations\/(?<locationId>(.*))\/conversationProfiles\/(?<conversationProfileId>(.*))");

  constructor() { }

  setQChatConversation(val){
    this.isQChatConversation = val;
  }

  getQChatConversation(){
    return this.isQChatConversation;
  }

  setSocketState(state :number) {
    this.socketState=state;
  }

  getSocketState() {
    return this.socketState;
  }

  setNewConversation(event) {
    this.newConversation.next(event);
  }

  getNewConversation () {
    return this.newConversation.asObservable();
  }

  setMessage(event) {
    this.message.next(event);
  }

  getMessage() {
    return this.message.asObservable();
  }
  getBroadcastChannel(){
    return this.broadcastChannel;
  }

  // BroadcastMessage
  setBroadcastMessage(data: Object): void {
    this.broadcastChannel.postMessage(JSON.stringify(data));
  }

  getTranscripts() {
    return this.transcripts.asObservable();
  }
  setTranscripts(transcripts) {
    this.transcripts.next(transcripts);
  }

  /**
   * Set the projectConfig
   * @param projectId
   */
  setProjectConfig(projectConfig) {
    this.projectConfig.next(projectConfig);
  }

  /**
   * Set Conversation
   * @param conversationId
   */
  setConversation(conversationId) {
    this.conversation.next(conversationId);
  }

  /**
   * Set client logo
   * @param img
   */
  setClientLogo(img) {
    this.clientLogo.next(img);
  }

  /**
   * return client logo
   */
  getClientLogo() {
    return this.clientLogo.asObservable();
  }

  /**
   * return projectId
   */
  getprojectConfig() {
    return this.projectConfig.asObservable();
  }

  /**
   *return conversation
   */
  getConversation() {
    return this.conversation.asObservable();
  }

  /**
   * clear project
   */
  clearProjectConfig() {
    this.projectConfig = null;
  }

  setViewValue(newValue): void {
    this.view.next(newValue);
  }

  getViewValue(): Observable<boolean> {
    return this.view.asObservable();
  }

  setParticipantId(participantId): void {
    this.participantId.next(participantId);
  }

  getParticipantId(): Observable<string> {
    return this.participantId.asObservable();
  }

  setCopyValue(text): void {
    this.copyToChatbox.next(text);
  }

  getCopyValue(): Observable<string> {
    return this.copyToChatbox.asObservable();
  }

  getGalleryImagesValues(): Observable<[]> {
    return this.galleryImages.asObservable();
  }

  setGalleryImagesValues(images): void {
    this.galleryImages.next(images);
  }

  getDocumentsValues(): Observable<[]> {
    return this.documents.asObservable();
  }

  setDocumentsValues(d): void {
    this.documents.next(d);
  }
  /**
  * clear conversation
  */
  clearConversation() {
    this.conversation = null;
  }

  getLocationId(): { [key: string]: string; } {
    return this.regex.exec(JSON.parse(localStorage.getItem('userData'))['conversationProfileId']).groups;
  }
}
