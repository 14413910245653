
import { AgmCoreModule } from '@agm/core';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
// import { HTTP_INTERCEPTORS } from '@angular/common/http';
// import { HttpTokenInterceptor } from './modules/agent/interceptors/http.token.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreModule } from '@ngrx/store';
import { DataService } from '../app/modules/agent/services/data.service';
import { WebsocketService } from '../app/services/websocket.service';
import { environment } from './../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuardService } from './guards/auth-guard.service';
import { NetworkStatusComponent } from './modules/shared/components/network-status/network-status.component';
import { LocalStorageService } from './services/local-storage.service';
import { SidenavService } from './services/sidenav.service';
import { ThemeService } from './services/theme.service';
import { reducer } from './store/reducer/conversations.reducer';

@NgModule({
  declarations: [
    AppComponent,
    NetworkStatusComponent
  ],
  imports: [
    BrowserAnimationsModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireModule,
    AngularFireAuthModule,
    HttpClientModule,
    AgmCoreModule.forRoot({
      apiKey: environment.agmAPIKey,
      libraries: ['places']
    }),
    StoreModule.forRoot({
      stateStore : reducer,
    }),
    MatGoogleMapsAutocompleteModule,
  ],
  providers: [
    LocalStorageService,
    ThemeService,
    AgmCoreModule,
    DataService,
    WebsocketService,
    AuthGuardService,
    SidenavService
  ],
  bootstrap: [AppComponent],
  entryComponents: []
})
export class AppModule { }
