// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyCg1Vq70kHKJ_8jac7cCY9fNnGtPo6V-hY",
    authDomain: "q-gcp-psc1-qai-cxt-dev-21-09.firebaseapp.com",
    projectId: "q-gcp-psc1-qai-cxt-dev-21-09",
    storageBucket: "q-gcp-psc1-qai-cxt-dev-21-09.appspot.com",
    messagingSenderId: "527765225059",
    appId: "1:527765225059:web:f9727f918e7dc4d4e68256",
    measurementId: "G-KGRV4WTKGZ"
  },
  // URL : 'ws://localhost:3000',
  // apiGatewayUrl : 'http://localhost:3000/agent-desktop-be-ccai/api',
  URL: 'wss://cxt-dev-api.q-appliedai.com',
  apiGatewayUrl: 'https://cxt-dev-api.q-appliedai.com/agent-desktop-be-ccai/api',
  agentassistUrl: 'https://ccai-service-dot-qp-qai-agentassist-2020-05.uc.r.appspot.com',
  salesForceUrl: 'https://dev-salesforce-service-dot-qai-insurance-life-prod.appspot.com',
  apiVersion: 'v1/ccai',
  v3ApiVersion: 'v1/dialogflow/cx/sessions',
  v2ApiVersion: 'v1/dialogflow/es/sessions',
  collections: {
    CCAI: 'conversations',
    CX: 'session-v3',
    ES: 'session-es'
  },
  channels: {
    whatsapp: 'WHATSAPP',
    telephony: 'TELEPHONY',
    chat: 'CHAT',
    twitter: 'TWITTER'
  },
  tokenExpiryTime: 24 * 3600 * 1000,
  agmAPIKey: 'AIzaSyAdkw2Zszplzf0B7caQ6Gh3NWikrAriuT4',
  languageTranslationUrl: 'https://translation.googleapis.com/language/translate/v2'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
