import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { auth } from 'firebase/app';
import { environment } from '../../../../environments/environment';
import { LocalStorageService } from './../../../services/local-storage.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'any'
})
export class AuthService {

  isAuthenticatedUser: boolean;

  constructor(public _afAuth: AngularFireAuth,
    private _http: HttpClient,
    private _localStorageService: LocalStorageService) {
    this.isAuthenticatedUser = false;
  }

  googleLogin() {
    return this._afAuth.auth.signInWithPopup(new auth.GoogleAuthProvider().setCustomParameters({
      prompt: 'consent'
    }));
  }

  // login with google button
  googleAuthLogin(email: string, token: string) {
    const body = {
      emailId : email,
      token : token
    }
    return this._http.post(`${environment.apiGatewayUrl}/humanAgent/login`, body);
  }

  emailPasswordLogin(email: string, password: string) {
    // return this._afAuth.auth.signInWithEmailAndPassword(email, password);
    const body = {
      emailId : email, password : password
    }
    return this._http.post(`${environment.apiGatewayUrl}/humanAgent/login`, body);
  }

  //change password
  changePassword(agentEmail: string,oldPassword:string, newPassword: string) {
    // return this._afAuth.auth.signInWithEmailAndPassword(email, password);
    const body = {
      agentEmail : agentEmail, oldPassword : oldPassword, newPassword : newPassword
    }
    return this._http.patch(`${environment.apiGatewayUrl}/humanAgent/changePassword`, body);
  }

  //OTP Generation
  OtpGeneration(agentEmail: string) {
    const body = {
      agentEmail : agentEmail
    }
    return this._http.patch(`${environment.apiGatewayUrl}/humanAgent/generateOTP`, body)
  }

  //verify OTP
  OtpVerification(agentEmail: string, otp: any) {
    const body = {
      agentEmail : agentEmail, otp : otp
    }
    return this._http.post(`${environment.apiGatewayUrl}/humanAgent/verifyOTP`, body)
  }

  //reset password
  resetPassword(agentEmail: string, newPassword: string){
    const body = {
      agentEmail : agentEmail, newPassword : newPassword
    }
    return this._http.patch(`${environment.apiGatewayUrl}/humanAgent/resetPassword`, body)
  }

  googleLogout() {
    this.isAuthenticatedUser = false;
    return this._afAuth.auth.signOut();
  }

  getIsAuthenticated() {
    let token = this._localStorageService.localStorageGetItem('token');
    if (token) {
      return this._http.post(`${environment.apiGatewayUrl}/auth/verifyToken`, { token: token });
    }
    else {
      return null;
    }
  }

  getUserDetails(userId): Observable<any>{
    let httpHeaders = { headers: new HttpHeaders({ Authorization: 'Bearer ' + localStorage.getItem('token') })};
    return this._http.get(`${environment.apiGatewayUrl}/humanAgent/getAgentDetails?agentId=${userId}`,httpHeaders);
  }

  getToken(username?: string, password?: string){
    let body = {
      emailId : username,
      password: password,
      type: 'USER'
    }
    return this._http.post(`${environment.apiGatewayUrl}/auth/generateToken`, body);
  }
}
