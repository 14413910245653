import { toggleAvailable , initToggle , darkMode, setComponentState} from '../actions/conversations.action';
import { createReducer, on } from '@ngrx/store'

//create a dummy initial state
export interface State {
    available: boolean,
    dark: boolean,
    componentState: boolean,
    conversations: Array<object>
}
const initialState: State = {
    available: true,
    dark: false,
    componentState: true,
    conversations: []
}
const _createReducer = createReducer(initialState,
    on(toggleAvailable, (state) => {
        return {
            ...state,
            available: !state.available
        };
    }),
    on(initToggle, (state,action) => {
        let availableCopy = JSON.parse(JSON.stringify(state.available))
        availableCopy = action.available;
        return {
            ...state,
            available: availableCopy
        };
    }),
    on(darkMode, (state) => {
        return {
            ...state,
            dark: !state.dark
        };
    }),
    on(setComponentState, (state) => {
        return {
            ...state,
            componentState: false
        };
    })
);
export function reducer(state, action) {
    return _createReducer(state, action);
}
